var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", [
                _vm._v("Selected Cluster Locations (" + _vm._s(_vm.type) + ")")
              ]),
              _vm.storeLocations.length > 0
                ? _c(
                    "small",
                    {
                      staticStyle: { "text-align": "center", display: "block" }
                    },
                    [
                      _vm._v(
                        "Select store locations to create new store cluster"
                      )
                    ]
                  )
                : _vm._e(),
              _c("button", {
                ref: "catalogueStoreLocationsPopupCloseButton",
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              })
            ]),
            _c("div", { staticClass: "modal-body  checklist" }, [
              _vm.storeLocations.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectAll,
                                expression: "selectAll"
                              }
                            ],
                            attrs: { type: "checkbox", id: "all-stores" },
                            domProps: {
                              checked: Array.isArray(_vm.selectAll)
                                ? _vm._i(_vm.selectAll, null) > -1
                                : _vm.selectAll
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.selectAll,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectAll = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectAll = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectAll = $$c
                                  }
                                },
                                _vm.onSelectAll
                              ]
                            }
                          }),
                          _c("label", { attrs: { for: "all-stores" } }, [
                            _vm._v("Select All tasks")
                          ])
                        ])
                      ]),
                      _c(
                        "VuePerfectScrollbar",
                        { staticClass: "checklist-contain" },
                        [
                          _c("hr"),
                          _c(
                            "ul",
                            { staticClass: "checklist-body" },
                            _vm._l(_vm.storeLocations, function(sl) {
                              return _c(
                                "li",
                                {
                                  key: sl.id,
                                  on: {
                                    click: function($event) {
                                      return _vm.setSelected(sl, !sl.selected)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "checklist-item" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            diplay: "block",
                                            width: "95%"
                                          }
                                        },
                                        [
                                          _c("label", [
                                            _c("strong", [
                                              _vm._v(_vm._s(sl.store.name))
                                            ])
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(sl.address) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.inSelectedStoresList(sl)
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                "/img/Create Pickl/storenameSelect_icon.png"
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c("hr"),
                      _vm.selectedLocations.length > 0
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.clusterName,
                                expression: "clusterName"
                              }
                            ],
                            staticStyle: {
                              width: "50%",
                              "border-radius": "10px"
                            },
                            attrs: {
                              type: "text",
                              placeholder: "Enter Cluster Name"
                            },
                            domProps: { value: _vm.clusterName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.clusterName = $event.target.value
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.selectedLocations.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-positive pull-right",
                              staticStyle: {
                                "margin-top": "5px",
                                "margin-bottom": "15px"
                              },
                              on: { click: _vm.createStoreCluster }
                            },
                            [_vm._v("Create Cluster")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading && _vm.storeLocations.length == 0
                ? _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("No Store Location Found!")
                  ])
                : _vm._e(),
              _vm.loading
                ? _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Loading Store Locations")
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }