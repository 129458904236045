

































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

@Component({
  components: {
    VuePerfectScrollbar
  }
})
export default class CatalogueStoreLocationsPopup extends Vue {
    @Prop() popupId!: string
    @Prop() brandId!: number
    @Prop() storeLocations!: any
    @Prop() loading!: boolean
    @Prop() type!: string

    public selectedLocations: any = []
    public selectAll: boolean = false
    public daysCount: number= 30
    public clusterName: string = ''

    get creditsLeft () {
      let credits = this.$store.state.brandCreditsRemaining - this.selectedLocations.length
      return credits
    }

    inSelectedStoresList (location: any) {
      let isObjectPresent = this.selectedLocations.find((o:any) => o.id === location.id)
      if (typeof isObjectPresent !== 'undefined') {
        return true
      } else {
        return false
      }
    }
    setSelected (location: any, selected: boolean) {
      let isObjectPresent = this.selectedLocations.findIndex((o:any) => o.id === location.id)
      if (isObjectPresent === -1) {
        this.selectedLocations.push({ id: location.id })
      } else {
        this.selectedLocations.splice(isObjectPresent, 1)
      }
    }
    onSelectAll (event: any) {
      if (this.selectAll) {
        this.storeLocations.forEach((location: any) => {
          let isObjectPresent = this.selectedLocations.find((o:any) => o.id === location.id)
          if (typeof isObjectPresent !== 'undefined') {
            return false
          }
          let selectedRetailerIndex = this.selectedLocations.findIndex((o:any) => o.id === location.id)
          if (selectedRetailerIndex === -1) {
            this.selectedLocations.push({ id: location.id })
          }
        })
      } else {
        this.storeLocations.forEach((location: any) => {
          let selectedRetailerIndex = this.selectedLocations.findIndex((o:any) => o.id === location.id)
          if (selectedRetailerIndex !== -1) {
            this.removePickl(selectedRetailerIndex)
          }
        })
      }
    }

    createStoreCluster () {
      if (this.clusterName === '') {
        this.$notify({ text: 'Please Enter Cluster Name', type: 'error' })
        return false
      }
      if (this.selectedLocations.length === 0) {
        this.$notify({ text: 'Please select store locations to create store cluster', type: 'error' })
        return false
      }
      this.$store.dispatch('createStoreCluster', { storeLocations: this.selectedLocations, brandId: this.brandId, name: this.clusterName }).then((response) => {
        let closeButton: HTMLElement = this.$refs.catalogueStoreLocationsPopupCloseButton as HTMLElement
        closeButton.click()
        this.$emit('clusterCreated')
        this.$notify({ text: 'cluster created successfully!', type: 'success' })
      })
    }

    removePickl (index: number) {
      this.selectedLocations.splice(index, 1)
    }
}
