import { render, staticRenderFns } from "./DeleteLocationCataloguePopup.vue?vue&type=template&id=84819672&scoped=true&"
import script from "./DeleteLocationCataloguePopup.vue?vue&type=script&lang=ts&"
export * from "./DeleteLocationCataloguePopup.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteLocationCataloguePopup.vue?vue&type=style&index=0&id=84819672&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84819672",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/naveenkumar/Sites/pickl-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('84819672')) {
      api.createRecord('84819672', component.options)
    } else {
      api.reload('84819672', component.options)
    }
    module.hot.accept("./DeleteLocationCataloguePopup.vue?vue&type=template&id=84819672&scoped=true&", function () {
      api.rerender('84819672', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/DeleteLocationCataloguePopup.vue"
export default component.exports