var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
    _vm.$store.state.settings.enable_location_catalogue_feature == 1
      ? _c(
          "div",
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "button",
                {
                  staticClass: "btn-positive pull-right",
                  attrs: { "data-toggle": "modal" },
                  on: { click: _vm.togglePicklContentSelectable }
                },
                [_vm._v(_vm._s(_vm.popButtonName))]
              ),
              _vm._v("  \n      ")
            ]),
            _vm.selectableClustersDiv
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-12 green-border-box white-bg",
                      staticStyle: { padding: "1%" }
                    },
                    [
                      _c("div", { staticClass: "checklist-head pull-left" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectAllPickls,
                              expression: "selectAllPickls"
                            }
                          ],
                          attrs: { type: "checkbox", id: "all-pickls" },
                          domProps: {
                            checked: Array.isArray(_vm.selectAllPickls)
                              ? _vm._i(_vm.selectAllPickls, null) > -1
                              : _vm.selectAllPickls
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.selectAllPickls,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectAllPickls = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectAllPickls = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectAllPickls = $$c
                                }
                              },
                              _vm.onSelectAll
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "all-pickls" } }, [
                          _vm._v("Select All Clusters")
                        ])
                      ]),
                      _vm.selectedClustersList.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "pull-right btn-positive",
                              attrs: {
                                "data-target":
                                  "#catalogue-store-location-popup",
                                "data-toggle": "modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getClusterStoreLocations(
                                    "approved"
                                  )
                                }
                              }
                            },
                            [_vm._v("SHOW APPROVED STORE LOCATIONS")]
                          )
                        : _vm._e(),
                      _vm._v("  \n           "),
                      _vm.selectedClustersList.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "pull-right btn-positive",
                              attrs: {
                                "data-target":
                                  "#catalogue-store-location-popup",
                                "data-toggle": "modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getClusterStoreLocations(
                                    "not-approved"
                                  )
                                }
                              }
                            },
                            [_vm._v("SHOW NOT COMPLETED STORE LOCATIONS")]
                          )
                        : _vm._e(),
                      _vm._v("  \n        ")
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.locationCatalogues, function(catalogue) {
                return _c(
                  "div",
                  {
                    key: catalogue.id,
                    staticClass: "col-xs-12 col-sm-6 item-list",
                    class: _vm.selectableClustersDiv ? "selectable" : ""
                  },
                  [
                    _c("LocationCatalogueItem", {
                      class: _vm.isSelectedCluster(catalogue.id)
                        ? "bottom-margin pickl-selected"
                        : "bottom-margin",
                      attrs: { catalogue: catalogue },
                      on: {
                        delete: function($event) {
                          _vm.actionCatalogue = catalogue
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.toggleSelectedPicklList(catalogue.id)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "text-center" }, [
              _vm.hasLoadMore
                ? _c(
                    "button",
                    {
                      staticClass: "btn-positive",
                      attrs: { disabled: _vm.busy },
                      on: { click: _vm.loadMore }
                    },
                    [_vm._v("Load more")]
                  )
                : _vm._e()
            ]),
            !_vm.busy &&
            (!_vm.locationCatalogues || _vm.locationCatalogues.length == 0)
              ? _c("h3", { staticClass: "text-center mt20" }, [
                  _vm._v("\n        No Store Cluster found.\n      ")
                ])
              : _vm._e(),
            _c("DeleteLocationCataloguePopup", {
              attrs: {
                brandId: _vm.brandId,
                popupId: "delete-location-catalogue-popup",
                catalogue: _vm.actionCatalogue
              },
              on: { deleted: _vm.refresh }
            }),
            _c("CatalogueStoreLocationsPopup", {
              attrs: {
                storeLocations: _vm.storeLocations,
                loading: _vm.loadingCatalogueStoreLocations,
                brandId: _vm.brandId,
                type: _vm.storeLocationType,
                popupId: "catalogue-store-location-popup"
              },
              on: { clusterCreated: _vm.refresh }
            })
          ],
          1
        )
      : _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Store Cluster Feature is not available!")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }