


































import LocationCatalogueItem from '@/components/common/LocationCatalogueItem.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DeleteLocationCataloguePopup from '@/components/common/DeleteLocationCataloguePopup.vue'
import CatalogueStoreLocationsPopup from '@/components/Brand/CatalogueStoreLocationsPopup.vue'

@Component({
  components: {
    LocationCatalogueItem,
    DeleteLocationCataloguePopup,
    CatalogueStoreLocationsPopup
  }
})
export default class LocationCatalogues extends Vue {
  public query = ''
  public limit = 1500
  public offset = 0
  public brandId = 0
  public actionCatalogue: any = null
  public locationCatalogues: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public product: {name: string, section: string, description:string, images: any[]} = {
    name: '',
    section: '',
    description: '',
    images: []
  }
  public productDetails: object = {}
  public selectableClustersDiv: boolean = false
  public selectedClustersList:any[] = []
  public selectAllPickls: boolean = false
  public loadingCatalogueStoreLocations: boolean = true
  public storeLocations: any[] = []
  public storeLocationType: string = 'approved'

  get popButtonName () {
    if (this.selectableClustersDiv === true) {
      return 'CANCEL SELECT'
    } else {
      return 'SELECT'
    }
  }

  getClusterStoreLocations (type: any) {
    this.loadingCatalogueStoreLocations = true
    this.storeLocationType = type
    this.$store.dispatch('getClusterLocations', { type: type, ids: this.selectedClustersList, brand_id: this.brandId }).then((response) => {
      this.storeLocations = response.response.body
      this.loadingCatalogueStoreLocations = false
    }, (response) => {
      this.busy = false
    })
  }

  toggleSelectedPicklList (clusterId: any) {
    if (this.selectedClustersList.includes(clusterId)) {
      this.selectedClustersList.splice(this.selectedClustersList.indexOf(clusterId), 1)
    } else {
      if (this.selectableClustersDiv) {
        this.selectedClustersList.push(clusterId)
      }
    }
  }
  togglePicklContentSelectable () {
    this.selectableClustersDiv = !this.selectableClustersDiv
    if (this.selectableClustersDiv === false) {
      this.selectedClustersList = []
      this.selectableClustersDiv = false
    }
  }
  onSelectAll () {
    this.selectedClustersList = []
    if (this.selectAllPickls) {
      this.locationCatalogues.forEach((pickl: any) => {
        this.selectedClustersList.push(pickl.id)
      })
    }
  }
  isSelectedCluster (clusterId: any) {
    if (this.selectedClustersList.includes(clusterId)) {
      return true
    } else {
      return false
    }
  }
  getLocationCatalogues (clear = true) {
    this.busy = true
    this.$store.dispatch('getLocationCatalogueList', { query: this.query, limit: this.limit, offset: this.offset, brand_id: this.brandId }).then((response) => {
      if (response.options.query === this.query && response.options.limit === this.limit && this.offset === response.options.offset) {
        if (clear) this.locationCatalogues = []
        this.locationCatalogues.push(...response.response.body.locationCatalogues)
        this.totalCount = response.response.body.count
        this.busy = false
      }
    }, (response) => {
      this.busy = false
    })
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getLocationCatalogues(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getLocationCatalogues()
    }, 500)()
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.getLocationCatalogues()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getLocationCatalogues()
    this.selectableClustersDiv = false
    this.selectedClustersList = []
  }

  editProductDetails (product:object) {
    this.productDetails = product
  }

  get hasLoadMore (): boolean {
    return this.locationCatalogues.length < this.totalCount
  }
}
