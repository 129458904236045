



































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class LocationCatalogueItem extends Vue {
    @Prop() catalogue!: any
    redirectToEditSurveyPage (locationCatalogueId:any) {
      this.$router.push('/brand/location-catalogues/' + locationCatalogueId + '/edit')
    }
}
