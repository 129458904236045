var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box white-bg text-center pickl" },
    [
      _c("div", { staticClass: "top-part" }, [
        _c("div", { staticClass: "posRel" }, [
          _c("h4", {
            directives: [
              {
                name: "autosize",
                rawName: "v-autosize",
                value: 22,
                expression: "22"
              }
            ],
            domProps: { innerHTML: _vm._s(_vm.catalogue.name) }
          }),
          _c("p", {
            staticClass: "short-description",
            domProps: { innerHTML: _vm._s(_vm.catalogue.description) }
          })
        ]),
        _c("div", {}, [
          _c("div", { staticClass: "text-left float-left" }, [
            _c(
              "button",
              {
                staticClass: "btn-negative",
                attrs: {
                  "data-target": "#delete-location-catalogue-popup",
                  "data-toggle": "modal",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("delete", _vm.catalogue)
                  }
                }
              },
              [_vm._v("\n          DELETE\n        ")]
            )
          ]),
          _c("div", { staticClass: "text-right float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: {
                  "data-target": "#edit-product-popup",
                  "data-toggle": "modal",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.redirectToEditSurveyPage(_vm.catalogue.id)
                  }
                }
              },
              [_vm._v("\n          EDIT\n        ")]
            )
          ]),
          _c("div", { staticClass: "clearfix" })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }